<template>
  <div>
    <div>
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <div class="text-center sm:mt-0 sm:ml-4 sm:text-left pb-2">
                  <h3
                    class="text-xl leading-6 py-8 text-center"
                    id="modal-title"
                  >
                    <span
                      style="cursor: pointer"
                      @click="close()"
                      class="absolute right-5 z-50 top-5"
                      ><Exit
                    /></span>
                    <p
                      v-if="type === 1"
                      class="mx-8 my-4 text-left font-semibold"
                    >
                      {{ description.byt }}
                    </p>
                    <p
                      v-if="type === 2"
                      class="mx-8 my-4 text-left font-semibold"
                    >
                      {{ description.dum }}
                    </p>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Exit from "../svg/Exit.vue";
export default {
  props: {
    description: Object,
    type: Number,
  },
  components: {
    Exit,
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
